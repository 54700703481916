/* BinanceLogin.css */

.registation-dashboard {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #111;

    .login-box {
        background-color: #161B21;
        padding: 2rem;
        border-radius: 8px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
        width: 450px;

        color: white;
    }

    .img {
        height: 28px;
        width: auto;
    }

    .title {
        font-size: 25px;
        font-weight: bold;
        margin-bottom: 2rem;
        margin-top: 2rem;
        text-align: left;
        color: #fff;
    }

    .label {
        display: block;
        text-align: left;
        margin-bottom: 0.5rem;
        font-size: 14px;
        color: #eaecef;
        font-weight: 600;
    }

    .input {
        width: 100%;
        padding: 10px;
        border-radius: 10px;
        border: none;
        background-color: transparent;
        color: #EAECEF;
        margin-bottom: 1rem;
        height: 48px;
        border: 0.5px solid #eaecef4a;
    }

    .checkbox-container {
        display: flex;
        align-items: center;
        font-size: 13px;
        text-align: left;
        margin-bottom: 1rem;
        line-height: 20px;
        color: #eaecef;
    }

    .checkbox {
        margin-right: 8px;
    }

    .link {
        color: #3296CA;
        text-decoration: none;
    }

    .captcha-image {
        font-size: 18px;
        font-weight: 600;
        color: #3296CA;
        margin-bottom: 1rem;
    }

    .refresh-button {
        color: #3296CA;
        margin-bottom: 11px;
    }

    .button {
        width: 100%;
        background-color: #3296CA;
        color: #fff;
        padding: 10px;
        border-radius: 10px;
        font-weight: bold;
        border: none;
        cursor: pointer;

        height: 48px;
        transition: all 0.3s ease-in-out;

        &:hover {
            transform: scale(1.02);
            transition: all 0.3s ease-in-out;
        }
    }

    .separator {
        color: #fff;
        margin: 1rem 0;
        text-align: center;
    }

    .social-button {
        width: 100%;
        padding: 10px;
        border-radius: 10px;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        color: #eaecef;
        margin-bottom: 0.5rem;
        cursor: pointer;
        height: 48px;
        font-size: 14px;
        position: relative;
        font-weight: 600;
        border: 0.5px solid #eaecef4a;
        transition: all 0.3s ease-in-out;

        &:hover {
            transform: scale(1.02);
            transition: all 0.3s ease-in-out;
        }
    }

    .icon {
        margin-right: 10px;
        position: absolute;
        left: 24px;
    }

    .description {
        font-size: 15px;
        color: #bbb;
        margin-bottom: 1rem;
        color: #eaecef;
        line-height: 23px;

    }

    .input-container {
        position: relative;
        width: 100%;
    }

    .code-status {
        position: absolute;
        right: 10px;
        top: 22px;
        transform: translateY(-48%);
        color: #aaa;
        font-size: 12px;
    }

    .resend {
        color: #3296CA;
        font-size: 16px;
        cursor: pointer;
        text-align: center;
        margin-top: 2rem;
        font-weight: 600;
    }

    .password-box {
        background-color: #21262e;
        padding: 20px;
        border-radius: 10px;
        width: 350px;
        text-align: center;
    }

    .requirements {
        text-align: left;
        color: #bbb;
        font-size: 12px;
        margin-top: 10px;
        list-style: none;
    }

    .toggle-button {
        position: absolute;
        right: 0;
        top: 13px;
        right: 10px;
        background: transparent;
        border: 0;
        color: white;
    }

    .password-requirements {
        padding: 0;
    }

    .requirements span {
        display: block;
        margin-bottom: 5px;
    }

    .valid {
        color: #3296CA;
        list-style: none;
        margin-bottom: 1rem;
    }

    .invalid {
        list-style: none;
        margin-bottom: 1rem;
        color: #eaecef;
    }
}



// 

/* BinanceEmailVerification.css */






// .label {
//     display: block;
//     text-align: left;
//     font-size: 0.9rem;
//     margin-bottom: 0.5rem;
// }

// .input-container {
//     position: relative;
//     width: 100%;
// }

// .input {
//     width: 100%;
//     padding: 10px;
//     border-radius: 5px;
//     border: none;
//     background-color: #333;
//     color: white;
//     margin-bottom: 1rem;
//     padding-right: 60px;
// }

@media only screen and (min-width: 200px) and (max-width: 900px) {
    .registation-dashboard {
        display: flex;
        justify-content: center;
        /* align-items: center; */
        height: 100vh;
        background-color: #111;
        overflow: scroll;
        padding: 2rem 2%;

    }

    .registation-dashboard .title {
        font-size: 21px;
        margin-bottom: 1rem;
        margin-top: 1rem;
    }

    .registation-dashboard .login-box {

        padding: 2rem 5%;
    }
}